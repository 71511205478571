<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">FAQ {{ viewModel.isModify ? '수정' : '등록' }}</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>카테고리<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <div class="group_form">
              <Selectbox
                :dataList="viewModel.model.categoryDataList | setDataList('id', 'categoryName')"
                :value.sync="viewModel.model.categoryId"
                maxWidth="300px"/>
              <Button
                btnSize="small"
                btnStyle="secondary"
                text="카테고리 관리"
                @onClickBtn="viewModel.onClickCategoryPoup()"/>
            </div>
          </td>
        </tr>
        <tr>
          <th>Q. 질문<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="질문을 입력해주세요."
              :value.sync="viewModel.model.question"/>
          </td>
        </tr>
        <tr>
          <th>A. 답변<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Textarea
              placeholder="답변을 입력해주세요."
              :value.sync="viewModel.model.answer"
              :showLine="10"
              :isCount="true"/>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="SERVICE_FAQ"/>
      </div>
      <div class="area_right">
        <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          @onClickBtn="viewModel.onClickComplete()"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <FaqCagegoryPopup
        v-if="viewModel.isFaqCategoryPopup"
        :dataList="viewModel.model.categoryDataList"
        @onModifyOrder="orderData => viewModel.onModifyOrder(orderData)"
        @onClickAdd="viewModel.onClickAddCategory()"
        @onClickDelete="id => viewModel.onClickDeleteCategoryItem(id)"
        @onClickClose="viewModel.onClickCloseFaqCagegoryPopup()"
        @onClickComplete="viewModel.onClickCompleteFaqCagegoryPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import FaqCagegoryPopup from '@/views/service/faq/view/popup/FaqCagegoryPopup';
// viewModel
import FaqDetailViewModel from '@/views/service/faq/viewModel/FaqDetailViewModel'

export default {
  name:'TermRegister',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Selectbox,
    Input,
    Textarea,
    Sticky,
    Button,
    FaqCagegoryPopup,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new FaqDetailViewModel(),
    }
  },
}
</script>